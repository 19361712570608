/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  deliveryId,
  pickupId,
} from "../Helpers/Config";


import { addressFormat,stripslashes, getTimeFrmDate } from "../Helpers/SettingHelper";

import footerLogo from "../../common/images/foot-logo.png";
import facebook from "../../common/images/facebook.png";
import instagram from "../../common/images/instagram.png";
import youtube from "../../common/images/youtube.png";
import Address from "../../common/images/placeholder.png";
import Telephone from "../../common/images/telephone.png";
import Email from "../../common/images/email.png";
import Location from "../../common/images/location_btn.png";
import Phone from "../../common/images/telephone.png";


import { GET_STATIC_BLOCK, GET_GLOBAL_SETTINGS, GET_ALL_OUTLETS} from "../../actions";
var Parser = require("html-react-parser");
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { footerBlocks: "", footerlink: "", globalsettings: [], outlets: []};
  }

  componentDidMount() {
    this.props.getAllOutlets(pickupId);
    this.props.getStaticBlock();
    this.props.getGlobalSettings();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }

  componentWillReceiveProps(PropsData) {
 
     if (PropsData.outlets !== this.props.outlets) {
      if (PropsData.outlets && PropsData.outlets[0].status == "ok") {
        $("#dvLoading").fadeOut(2000);
        this.setState({
          outlets: PropsData.outlets[0].result_set,
        });
      }
    }

    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "footer-contents") {
            footerPas = data.staticblocks_description;
            return "";
          }
        });
      }
      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: PropsData.staticblack,
        footerlink: footerPas,
      });
    }

   if (this.state.globalsettings !== PropsData.globalsettings) {
      if (PropsData.globalsettings.length > 0) {
        this.setState({
          globalsettings: PropsData.globalsettings[0].result_set,
        });
      }
    }

  }


  getOutletData  = (dataProp) => {
    if (dataProp) {
      return dataProp.map((item, index) => {
          return (
              <div className="location-details">
                <div className="addr-dtls">
                  <h5>{stripslashes(item.outlet_name)}</h5>
                  <ul className="footer-top-address">
                    <li>
                      <p className="footer-para-address">
                        <p className="footer-para-image"><img className="facebook" src={Location} /></p>
                          <span className="footer-top-address-inner">
                            {item.outlet_address_line1}
                            {item.outlet_unit_number2 !== ""
                            ? "#" +
                            item.outlet_unit_number1 +
                            "-" +
                            item.outlet_unit_number2
                           : item.outlet_unit_number1}{" "}
                           Singapore {item.outlet_postal_code}
                          </span>
                      </p>
                    </li>
                    <li>
                      <p><p className="footer-para-image"><img className="instagram" src={Phone} /></p> 
                        <a href={
                          "tel:" +
                          item.outlet_phone
                          }>{item.outlet_phone}
                        </a>
                      </p> 
                    </li>
                    <li>
                      <p>
                        <p className="footer-para-image"><img className="youtube" src={Email} /></p>
                        <a href={
                            "mailto:" +
                            item.outlet_email
                          }>{item.outlet_email}
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <p className="footer-para-image">Reservations: </p>
                        <a>
                         Click here
                        </a>
                      </p>
                    </li>

                  </ul>
                </div>
              </div>
          );
     });
    }
  }

  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (
      <footer className="footer-main">
        <div className="container-full">
          <div className="footer-top">
            <a className="footer-logo footer-top-logo-div" title="Food etc." href="/">
            <img src={footerLogo} /></a>
            <div className="footer-top-inner">

            </div>
          </div>

          {this.state.footerlink}

          <div className="copyright-section">
            <p>Copyright {yearSp}  Food etc. All rights reserved. Design By Jankosoft</p>
          </div>
        </div>

        <div className="scrolltop show" id="scrollbutton">
          <a href="/" className="disbl_href_action"><span>
          <i className="fa fa-angle-double-up ars"></i>
          <i className="fa fa-angle-up ars1"></i>
          <i className="fa fa-angle-up ars2"></i></span>
          </a>
        </div>
      </footer>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }


  return {
    staticblack: blacksArr,
    globalsettings: state.settings,
    outlets: state.alloutlets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },   

    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
